import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    OmiseCard.configure({
      // @TODO: Set dynamic public key
      publicKey: "pkey_test_61dzm33cbsg0i7gyewb"
    });

    const button = document.querySelector("#register_button");
    const form = document.querySelector("#register_form");
    const total_amount_element = document.querySelector("#total_amount");
    const total_amount_text = total_amount_element.textContent || total_amount_element.innerText;
    const total_amount = parseFloat(total_amount_text);

    button.addEventListener("click", (event) => {
      event.preventDefault();
      OmiseCard.open({
        amount: total_amount,
        currency: "SGD",
        defaultPaymentMethod: "credit_card",
        onCreateTokenSuccess: (nonce) => {
          if (nonce.startsWith("tokn_")) {
            form.omise_token.value = nonce;
          } else {
            form.omise_source.value = nonce;
          };
          form.submit();
        }
      });
    });
  }
}
